
.gmodal {
    height: 100 vh;
    margin: 0 auto;
    max-width: 30%;
}
div[class^="Form__formSection"], div[class*=" Form__formSection"] 
{
    width: 30% !important;
}
body .layout-wrapper .layout-footer {
    position:relative;
    bottom:0;
    width: 85%;
}
body .p-progressbar .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background:  #492f8d;
}
body .p-button {
    background-color: #492f8d;
    border: 1px solid #492f8d;
    border-top-color: rgb(73, 47, 141);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(73, 47, 141);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(73, 47, 141);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(73, 47, 141);
    border-left-style: solid;
    border-left-width: 1px;
    border-image-outset: 0;
    border-image-repeat: stretch;
    border-image-slice: 100%;
    border-image-source: none;
    border-image-width: 1;
}
.p-button, .p-button * {
    background-color: #492f8d;
    border: 1px solid #492f8d;
    border-top-color: rgb(73, 47, 141);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(73, 47, 141);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(73, 47, 141);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(73, 47, 141);
    border-left-style: solid;
    border-left-width: 1px;
    border-image-outset: 0;
    border-image-repeat: stretch;
    border-image-slice: 100%;
    border-image-source: none;
    border-image-width: 1;
}
@media (max-width: 1024px) {
   
    body .layout-wrapper .layout-footer {
        position:relative;
        bottom:0;
        width: 100%;
    }
    
    
}
@media screen and (max-width: 40em) {
    .gmodal {
        height: 100 vh;
        margin: 0 auto;
        max-width: 90%;
    }
    .p-growl.p-growl-topright {
        top: 100px;
        width: 100%;
    }
    body .p-col {
        font-size: 10px;
    }
    body .layout-wrapper .layout-main {
        transition: margin-left 0.2s;
        padding: 38px 8px 8px 8px;
    }
    body .layout-wrapper .layout-footer {
        position:relative;
        bottom:0;
        width: 100%;
    }
    div[class^="Form__formSection"], div[class*=" Form__formSection"] 
    {
        width: 100% !important;
    }
    
}